ul.sideNav {
    padding: 2.42rem 1em 2.42rem 0;

    li {
        position: relative;
        padding-left: 4.35rem;
        text-transform: capitalize;
        word-break: break-word;

        a {
            font-weight: 500;
            font-size: 1.14rem;
            color: var(--cu-white);
            min-height: 3.85rem;
            display: flex;
            align-items: center;
            opacity: .5;
            transition: all .5s ease;

            &::before {
                transition: all .5s ease;
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                width: 0.35rem;
                background: transparent;
                z-index: 1;
                border-radius: 0px 0.71rem 0.71rem 0px;
            }
        }

        .linkActive {
            opacity: 1;

            &::before {
                background: var(--cu-blue);
            }
        }
    }
}