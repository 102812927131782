.breadcrumbs {
    &-layout {
        width: 12.5rem;
        height: 3.28rem;
    }

    &-title {
        font-weight: 500;
        font-size: 1.42rem;
        line-height: 1.71rem;
        text-transform: capitalize;
        color: var(--cu-white);
    }

    &-img {
        width: 2.5rem;
        height: 2.5rem;
        cursor: pointer;
    }

    ul {
        li {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.85rem;
            line-height: 1.07rem;
            text-transform: capitalize;
            color: var(--cu-gray-100);

            a,
            span {
                color: var(--cu-blue);
                cursor: pointer;
            }

        }
    }
}

.user-transaction-breadcrumbs {
    .breadcrumbs-layout {
        width: 15.5rem;
    }
}