.artbank {
    &-card {
        padding: 1.2rem 1rem 1.2rem 1rem;

        &-border {
            border-bottom: 1px solid var(--cu-gray-500);
        }
    }

    &-name {
        color: var(--cu-white);
        text-transform: capitalize;
        padding-bottom: 1rem;
    }

    &-card-full {
        flex: 1 1 32.14rem;
        max-width: 50%;

        @media (max-width: 1264px) {

            flex: 1 1 100%;
            max-width: 100%;

        }
    }

    &-title {
        font-weight: 500;
        font-size: 1.42rem;
        text-transform: capitalize;
        color: var(--cu-white);
    }

    &-label {
        color: var(--cu-gray-970);
    }

    &-value {
        font-weight: 400;
        word-break: break-all;
        color: var(--cu-white);
        padding-bottom: 0.85rem;
        min-height: 3.85rem;
        display: flex;
        align-items: center;
    }

    &-total {
        color: var(--cu-gray-970);
        display: flex;

        &-value {
            color: var(--cu-white);
        }
    }

    &-remove {
        padding: 0px;
        background-color: transparent !important;
        width: auto;
        color: var(--cu-blue);
        word-break: break-all;
    }
}


.add-wallet {
    .content-card {
        background: transparent;
        padding-bottom: 0px;
        margin-top: 0.71rem;
    }

    .content {
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
    }

    &-input {
        width: 100%;


    }

    .form-box {
        font-size: 1rem;
        outline: none;
        margin-top: 0.71rem;
    }

}

.remove-wallet {
    .content-card {
        padding-bottom: 0px;
    }


    &-input {
        width: 100%;
        word-break: break-all;

    }

    .form-box {
        font-size: 1rem;
    }

    .transaction-ok {
        background: var(--cu-brown);
    }

}