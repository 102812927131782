.security {
    &-action {
        .status {
            max-width: fit-content;
        }
    }

    &-popup {
        .popup-title {
            text-align: center;
            width: 100%;
        }


    }
}

.iframe-cls {
    height: 16rem;
    overflow: hidden;
}

.security-add-popup {
    .popup-body {
        padding-top: 1rem;
    }
}

.security-name {
    text-transform: capitalize;
}