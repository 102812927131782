.frame {
    &-card {
        min-height: calc(100% - 3rem);
        background-color: var(--cu-black-850);
        border-radius: 0.57rem;
        padding: 0.5rem;

        &-pic {
            padding: 0.14rem 1.71rem 0.92rem 1.71rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &-menu {
        width: 100%;
        align-items: flex-end;
        display: flex;
        justify-content: flex-end;
        color: var(--cu-white);
        padding-top: 0.28rem;
        padding: right;
        padding-right: 0.57rem;
    }
}

.frame.dropdown-menu-list {
    background: var(--cu-gray-700);

    ul {
        padding: 0.5rem 1rem;
        display: flex;
        background-color: var(--cu-black-trans-450);
        color: var(--cu-white);
        border-radius: 0.35rem;
        font-size: 0.85rem;
        gap: 0.42rem;

        &::after {
            background-color: var(--cu-black-trans-450);
        }
    }
}

.frames {
    ul {
        font-weight: 400;
        font-size: 1rem;
        word-break: break-all;
        color: var(--cu-white);
        text-transform: capitalize;

        li {
            font-size: 0.85rem;
            color: var(--cu-gray-960);

            span {
                color: var(--cu-white);
            }
        }
    }
}


.nft-frames {
    &-card {
        background: var(--cu-black-700);
    }

    ul {
        border-bottom: 1px solid var(--cu-gray-500);
    }

}

.total {
    font-size: 0.85rem;
    color: var(--cu-gray-960);
    word-break: break-all;

}

.frame .breadcrumbs-layout {
    width: 20rem;

}

.free-frames-pic {
    .frame-card-pic {
        height: 12.9rem;

        img {
            margin: auto;
            max-height: 100%;
            max-width: 100%;
        }

        .image-container {
            height: 100%;
        }
    }

}

.frame-list-container {
    grid-template-columns: repeat(auto-fill,minmax(15.25rem,1fr));
}