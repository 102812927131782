.tab-page {
    ul {
        display: flex;
        gap: 0.15rem;
        @include hr-border(bottom);
        li {
            a,p {
                border-radius: 0.35rem 0.35rem 0 0;
                display: block;
                position: relative;
                padding: 0.85rem 1.71rem;
                background: var(--cu-black-trans-200);
                color: var(--cu-gray-100);
                transition: all .5s ease;
                &::after{
                    transition: all .5s ease;
                    content: '';    
                    border-radius:  0.71rem 0.71rem 0 0;
                    height: 0.14rem;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background: transparent;
                    position: absolute;
                    z-index: 1;
                }
                &.tab-page-active {
                    background: var( --cu-gray-850);
                    color: var(--cu-white);
                    &::after{
                        background: var(--cu-blue);
                    }
                }
            }
            p{
                padding: 0.85rem 1rem !important; 
            }
        }
    }
}