.frame-tabs {
    border-bottom: 1px solid #323337;

}

.tab-pages {
    ul {
        display: flex;
        gap: 0.15rem;
        cursor: pointer;

        li {
            display: flex;
            justify-content: center;
            transition: all .5s ease;
            flex: 1;
            // border: 1px solid var(--cu-black-40);
            padding: 0.6875rem 1.75rem;
            //  margin-top: 0.5rem;
            // font-size: 1rem;
            background: rgba(39, 40, 50, 0.3);
            border-radius: 5px 5px 0px 0px;
            color: #B3B5BD;
            font-size: 14px;
            line-height: 17px;
            min-width: fit-content;

            &.tab-page-active {
                background: #272832;
                // border-radius: 5px 5px 0px 0px;
                // background-color: var(--cu-black-50);
                color: #FFFFFF;
                border-bottom: 2px solid #4C66F0;
            }

        }
    }
}

.top {
    color: #797B83;
    font-size: 14px;
}

.frame-upload-popup {
    .popup-wrapper {
        background: #1A1D27;
        padding: 1.5rem;
    }

    .popup-body {
        padding-top: 0px;
    }
}

.brdr-tp {
    //opacity: 0.16;
    border-bottom: 1px solid #323337;
    padding: 0.6rem 0px;

}

.reset-frame {
    text-decoration-line: underline;

    /* Colors/danger */

    color: #E23D3D;
}

.no-reset {
    text-decoration-line: underline;

    /* Colors/grey */

    color: #B3B5BD;
}

.cancel-disable {
    background: #B3B5BD;
    border: 1px solid var(--cu-white) !important;
    width: auto;
}
.cancelFramPop {
    .popup-header {
        gap: 2rem;
    }
    .popup-title {
        font-size: 1.25rem;
    }
    .popup-wrapper {
        width: 30rem;
        padding: 2rem 3rem;
        border-radius: 1.875rem;
    }
    .content-card{
        background-color: inherit;
        color: var(--cu-gray-100);
    }
    .transaction-ok {
        background-color: var(--cu-brown);
    }
}

.popup-frame-container {
    .image-container, .img-wid {
        height: calc(100vh - 30rem);
    }
    
}