.btn {
    &-full {
        width: 100%;
    }

    &-primary {
        background: var(--cu-blue);
        color: var(--cu-white);
        @include primeButton;
    }

    &-login {
        padding: 1.28rem 0;
        font-size: 1.28rem;
        @include border-radius;
    }

    &-eye {
        height: 1rem;
        width: 1.42rem;
        border: none;
        outline: none;
        @include backgroundfull;

        &-on {
            background-image: url(~/src/Assets/Images/eye_disabled.png);
        }

        &-off {
            background-image: url(~/src/Assets/Images/eye-on.svg);
            opacity: 0.2;
        }
    }

    &-link {
        color: var(--cu-white);
        text-decoration: underline;

        &-color {
            color: var(--cu-white-100);
        }
    }

    &-hamburg {
        @include border-radius;
        width: 1.8rem;
        height: 2rem;
        background-repeat: no-repeat;
        background-image: url(~/src/Assets/Images/hamburg.svg);
        background-size: 80%;
        background-position: center;
    }

    &-buttonlink {
        color: var(--cu-blue);
    }

    &-action {
        cursor: pointer;
        width: 1.4rem;

        &.card-menu {
            width: 0.29rem;
        }
    }

    &-close {
        width: 1.07rem;
        height: 1.07rem;
        @include backgroundfull;
        background-image: url(~/src/Assets/Images/close-sidebar.svg);
    }

    &-closes {

        width: 1.47rem;
        height: 1.47rem;
        @include backgroundfull;
        background-image: url(~/src/Assets/Images/close-sidebar.svg);

    }
}

.icon-person {
    opacity: 0.2;
    height: 2rem;
    width: 1.42rem;
    @include backgroundfull;
    background-image: url(~/src/Assets/Images/person-icon.svg);
}


.card-menu.dropdown-menu-list {
    ul {
        padding: 0.57rem 0.85rem 0.57rem 0.85rem;
        display: flex;
        background-color: var(--cu-black-trans-450);
        color: var(--cu-white);
        align-items: center;
        border-radius: 0.35rem;
        font-size: 0.85rem;
        margin-top: 0.2rem;

        &::after {
            background-color: var(--cu-black-trans-450);
        }
    }
}

.button-delete {
    background: var(--cu-brown);
    width: auto;
}

.button-accept {
    background: #189048;
    width: auto;
}

.button-cancel {
    background: transparent;
    width: auto;
}