.add-image {
    flex-basis: 23.6rem;

    align-items: center;
    display: flex;
    background: var(--cu-black-700);
    border-radius: 0.57rem;
    justify-content: center;
    flex-direction: column;

    img {
        max-width: 100%;
        margin: auto;
    }

    .image-label {
        align-items: flex-start;
    }

    &-frame {
        padding: 1.71rem;
        flex-basis: 100%;
        margin: auto;
    }

}

.add-form {
    flex: 1 1 48.92rem;
    background: var(--cu-black-700);
    flex-basis: 11.57rem;
    word-break: break-all;
    font-size: 1rem;

    span {
        color: var(--cu-gray-100);
        text-transform: capitalize;
    }

    &-edit {
        p {
            color: var(--cu-white);
        }
    }

}

.frame-form {
    flex: 1;

    &-desc {
        width: 100%;

        .textArea {
            margin-top: 0.21rem;
            height: 10rem;
        }
    }
}

.create {
    &-form-field {
        flex: 1 1 21.2rem;

    }
}

.nft-form {
    div {
        &:not(:last-child) {
            padding-bottom: 0.78rem;
            border-bottom: 1px solid var(--cu-gray-900);
        }
    }
}

.add-desc {
    flex-basis: 23.6rem;
    background: var(--cu-black-700);
    max-width: 23.6rem;
    border-radius: 0.57rem;

}

.des {
    word-break: break-all;
    font-size: 1rem;
    color: var(--cu-white);

    &-head {
        font-weight: 400;
        font-size: 1.14rem;
        color: var(--cu-gray-100);
    }
}

.nft-pic {
    flex-basis: 23.6rem;
}

.file-validate {
    margin-bottom: -1rem;
    margin-top: 0.5rem;
}

.frame-upload-popup {
    .popup-body {
        max-height: calc(100vh - 8rem);
    }
}