.gallery {
    &-image {
        max-width: 4rem;
        height: 3rem;
        overflow: hidden;
    }

    &-home {
        max-width: 7.14rem;
    }
}

.nft-hashtags {
    max-width: 10.5rem;
    word-break: break-all;
    white-space: break-spaces;

}

.wall-image-gallery {
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    width: 4rem;
}

.wall-image-details {
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

.menu {
    font-size: 0.85rem;
    border-radius: 0.42rem;

    &-reject {
        background-color: var(--cu-brown);
        padding: 0.42rem 1.28rem 0.42rem 1.28rem;
    }

    &-accept {
        padding: 0.42rem 1.07rem 0.42rem 1.07rem;
        background-color: var(--cu-green);

    }
}

.gallery-body {
    tr:hover {
        background-color: var(--cu-gray-940);
        cursor: pointer;
    }

}

.nft-user {
    .user {
        width: 7.21rem;
        border-radius: 50%;
        overflow: hidden;
        border-radius: 0%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 7rem;


        &-name {
            font-size: 1.42rem;
            font-weight: 500;
            color: var(--cu-white);
            text-transform: capitalize;
        }

        &-desc {
            margin-top: 0.92rem;
            font-size: 1.14rem;
            text-transform: capitalize;
        }

        &-outer {
            gap: 1.71rem;
        }
    }
}

.nft-accept {
    .transaction-ok {
        background: var(--cu-green);
    }
}

.nft-drop-loader.image-container:after {
    min-width: 1.57rem;
    min-height: 1.57rem;
}