.title {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.21rem;
    font-weight: 400;
    color: var(--cu-white);
}

.count {
    font-size: 1.71rem;
    line-height: 2.07rem;
    font-weight: 500;
    color: var(--cu-white);

    @media (max-width: 768px) {
        font-size: 1.4rem;

    }
}