:root {
   --cu-blue: #4C66F0;
   --cu-blue-60: #326BF2;
   --cu-blue-70:#14A0FA;
   --cu-gray-100: #B3B5BD;
   --cu-gray-500: #797A80;
   --cu-gray-700: #292b36;
   --cu-gray-800: #323645;
   --cu-gray-850: #272832;
   --cu-gray-900: #41444F;
   --cu-gray-910: #434346;
   --cu-gray-970: #83858C;
   --cu-gray-960: #8B8B8B;
   --cu-gray-980: #B8C0CC;
   --cu-gray-950: #15171f;
   --cu-gray-940: #17171f;
   --cu-gray-945: #111219;
   --cu-gray-930: #2d2f38;
   --cu-gray-1000: #484a54;
   --cu-gray-trans-200: rgb(121 122 128 / 16%);
   --cu-black: #000;
   --cu-black-5:#A0ABBB;
   --cu-black-10: #64748B;
   --cu-black-100: #30333A;
   --cu-black-600: #0D0F11;
   --cu-black-700: #1A1D27;
   --cu-black-800: #14141C;
   --cu-black-850: #1F222D;
   --cu-black-900:#14171F;
   --cu-black-trans-200: rgba(39, 40, 50, 0.3);
   --cu-black-trans-250: rgba(131, 133, 140, 0.4);
   --cu-black-trans-270: rgba(249, 249, 249, 0.8);
   --cu-black-trans-300: rgba(255, 255, 255, 0.03);
   --cu-black-trans-400: rgba(255, 255, 255, 0.04);
   --cu-black-trans-450: rgb(46 47 54);
   --cu-black-trans-500: rgba(0, 0, 0, .6);
   --cu-black-trans-600: rgb(18 19 28 / 90%);
   --cu-black-trans-700: rgba(179, 181, 189, 0.51);
   --cu-white: #fff;
   --cu-white-100: #A3A5A7;
   --cu-white-200: #F7F8F9;
   --cu-white-50: rgba(255, 255, 255, 0.86);
   --cu-white-70: #E0E0E0;
   --cu-white-trans-200: rgba(255, 255, 255, 0.2);
   --cu-white-trans-500: rgba(255, 255, 255, 0.5);
   --cu-green: #26B55F;
   --cu-green-100: #189048;
   --cu-brown: #E23D3D;
   --cu-blue: #4C66F0;
   --cu-blue-100: #4155bf;
   --cu-blue-150: #185BC3;
   --cu-red: red;
   --cu-black-20: #41444B;
}