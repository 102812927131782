.free-frames .popup-template {
    background-color: var(--cu-black-700);
}

.view {
    &-image {
        background: var(--cu-black-trans-300);
        padding: 6.14rem 5.21rem 6.57rem 6rem;

        img {
            min-width: 7.14rem;
            max-width: 17.07rem;
            width: 100%;
        }
    }

    &-details {
        padding: 2rem 2.78rem 2rem 2.78rem;
        text-transform: capitalize;
        flex: 1;

        span {
            font-size: 1.71rem;
            color: var(--cu-white);
        }

        p {
            font-size: 1rem;
            color: var(--cu-gray-100);
        }

    }

    &-free {
        background: linear-gradient(111.04deg, #7E15A2 13.54%, #853BA0 35.21%, #9C43BC 49.13%, #913AAF 61.92%, #7E15A2 85.77%);
        border-radius: 0.21rem;
        width: fit-content;
        padding: 0.3rem;
        font-size: 1.07rem;
        color: var(--cu-white);
        margin-top: 0.57rem;
    }

    &-card {
        flex: 1 1 12.42rem;
        min-height: 4.85rem;
        background: var(--cu-black-trans-300);
        border-radius: 0.35rem;
        padding: 0.92rem;
        color: var(--cu-gray-100);

        p {
            font-size: 0.85rem;
            opacity: 0.6;
        }

        span {
            font-size: 1.2rem;
            opacity: 0.8;
        }
    }

    &-desc {
        font-size: 1rem;
        color: var(--cu-white);
        line-height: 1.42rem;
        overflow: auto;
        max-height: calc(100vh - 38rem);
        padding-bottom: 1rem;
        max-width: 45rem;
        word-wrap: break-word;
    }

    &-total {
        min-width: 12.42rem;
        border-right: 1px solid var(--cu-gray-900);

        &-border {
            border-bottom: 1px solid var(--cu-gray-900);
        }
    }

    &-price {
        font-size: 1.28rem;
        color: var(--cu-white);
        margin-top: 0.57rem;
    }
}

.view-frames-popup {
    .popup-template-body {
        max-height: 100vh;
    }
}

.nft-view {
    .popup-template-body {
        max-height: calc(100vh - 6rem);
    }
}