.color-field{
    .color-box {
        background: var(--cu-gray-850);
        border-radius: 0.25rem;
        display: flex;
        flex-wrap: wrap;
        gap: 0.625rem;
        padding: 0.625rem 0.5em;
        .color-circle {
            width: 1rem;
            height: 1rem;
            border-radius: 0.125rem;
            cursor: pointer;
            border: 1px solid var(--cu-black-500);
            outline: 1px solid transparent;

            &.multi {
                background: linear-gradient(red, yellow, blue, orange);
            }

            &.selected {
                outline-color: var(--cu-white);
            }
        }
    }

}