.file-upload-wrapper{
    .error-validation p {
        font-size: 0.75rem;
    }
    .upload-file-section {
        background: var(--cu-black-700);
        border: 1px solid var(--cu-black-100);
        border-radius: 4px;
        height: 9.875rem;
        label {
          display: flex;
          height: 100%;
          width: 100%;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding: 2rem;
        }
        .upload-icon {
          display: none;
          background-color: var(--cu-black-700);
          margin: auto;
        }

          .file-desc{
            text-align: center;
            font-size: 0.75rem;
            color: var(--cu-black-10);
            &.active{
                color:var(--cu-white)
            }
          }
        
        .file-uploaded {
          padding: 0px;
          margin: auto;
          overflow: hidden;
        }

        &:hover {
          .upload-icon {
            display: inline;
          }
          .file-uploaded,.file-desc {
            display: none;
          }
        }
      }
}