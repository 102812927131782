.toggle-accordion {
  .nav {
    height: 100%;
    position: relative;
    z-index: 1;
    background: var(--cu-black-800);
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    &.nav-pills .nav-link {
      color: var(--cu-gray-100);
      transition: all 0.5s ease;
      font-size: 1rem;
      font-weight: 500;
      position: relative;
      text-transform: capitalize;
      padding:0.5rem 0.8rem 0.5rem 0.5rem;
      &.active,
      .nav-pills .show > .nav-link {
        background: var(--cu-gray-850);
        color: var(--cu-white);
      }
      &.active::after {
        transition: all 0.5s ease;
        content: "";
        border-radius: 0.71rem;
        height: 100%;
        width: 0.125rem;
        bottom: 0;
        right: 0.0625rem;
        background: var(--cu-blue);
        position: absolute;
        z-index: 1;
      }
    }
  }
  .tab-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    .tab-pane {
      transform: translate(-102%, 0%);
      padding: 0.575rem 0.5rem;
      &.active {
        transition: all 0.5s ease;
        transform: translate(60%, 0%);
      }
    }
  }
}
