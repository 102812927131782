.transaction {
    background: var(--cu-black-trans-300);
    border-radius: 0.35rem;
    padding: 1.14rem 0.71rem 1.14rem 0.71rem;
    color: var(--cu-gray-100);
    font-size: 0.78rem;

    &-header {
        width: 100%;
        height: 11.35rem;
        display: flex;
        align-items: center;
    }

    &-card {
        gap: 1.14rem;
    }

    &-details {
        flex-basis: 10rem;
    }

    &-sub {
        flex-basis: 9.5rem;
    }

    &-address {
        font-size: 1.14rem;
        line-height: 1.14rem;
        color: var(--cu-white);
    }

    &-input {
        background-color: transparent;
        color: var(--cu-white-70);
        outline: none;
        font-size: 1rem;
        letter-spacing: 0.05rem;
        width: 100%;
        word-break: break-all;
    }

    &-full {
        width: 100%;
    }
}

.transaction {
    &-button {
        width: 7.64rem;
        gap: 0.71rem;
        width: 100%;
    }

    &-cancel {
        background-color: transparent !important;
        border: 1px solid var(--cu-white) !important;
        width: auto;
    }

    &-ok {
        width: auto;
    }
}

.no-rows{
    color: var(--cu-gray-500);
    font-size: 1.24rem;
}