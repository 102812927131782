.communities {
  table {
    
    td:not(:first-child),td .name-span {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 8.5rem;
    }
    td .name-span{
      max-width: 8.625rem;
    }
    tbody {
      tr {
        cursor: default !important;
      }
    }
  }
}
