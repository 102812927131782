.status {
    color: var(--cu-white);
    border-radius: 0.42rem;
    font-size: 0.85rem;
    display: flex;
    justify-content: center;
    padding: 0.2rem 0.31rem 0.2rem 0.31rem;

    &-blocked {
        background-color: var(--cu-brown);
    }

    &-active {
        background-color: var(--cu-green);
    }

    &-success {
        padding: 0.42rem 0.85rem;
        width: fit-content;
    }
}