.headerArea-wrap {
    .pageTitle {
        font-size: 1.71rem;
        font-weight: 500;
        color: var(--cu-white);
    }

    &-right {
        flex-basis: 14.14rem;

        ul {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &-dp {
        height: 3.57rem;
        overflow: hidden;
        width: 3.57rem;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
}

.settings.dropdown-menu-list {
    ul {
        padding: 1rem 1.9rem 1rem 1.9rem;
        display: flex;
        background-color: var(--cu-gray-700);
        color: var(--cu-white);
        border-radius: 0.35rem;
        font-size: 0.85rem;
        align-items: flex-start;

        &::after {
            background-color: var(--cu-gray-700);
            left: 0.32rem;
        }
    }
}