.content {
    font-size: 1.28rem;
    font-style: normal;
    font-weight: 400;
    color: var(--cu-gray-100);

    &-card {
        width: 100%;
        background: var(--cu-black-trans-300);
        border-radius: 0.21rem;
        padding-bottom: 0.5rem;
        overflow: auto;
        scroll-behavior: smooth;
    }
}

.delete-user{
    .transaction-ok {
        background: var(--cu-brown);
    }
}
.no-content{
    margin-top: -1rem;
    .content{
        padding-bottom: 1rem;
    }
}