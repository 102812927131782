.project-management {
    .popup-wrapper {
        background: var(--cu-black-700);
    }
    .upload-file-container {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        .upload-file-item {
            background-color: var(--cu-gray-945);
            padding: 2rem;
            color: var(--cu-gray-500);
            .upload-text {
                color: var(--cu-blue);
                text-decoration: underline;
                text-underline-offset: 3px;
            }
            .file-name {
                color: var(--cu-white);
                font-weight: 500;
                font-size: 1.125rem;
            }
            .remove-text {
                color: var(--cu-brown);
                text-decoration: underline;
                text-underline-offset: 3px;
                font-size: 1rem;
            }
        }
    }
    .duplicate-container {
        .duplicate-head-text p{
            color: var(--cu-gray-100);            
        }
        .duplicate-item {            
            background-color: var(--cu-gray-945);
            .duplicate-content {
                .label {
                    color: var(--cu-gray-970);
                }
                .value {
                    color: var(--cu-white);
                }
            }
        }
    }
}