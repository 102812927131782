.settings {
    &-profile {
        &-title {
            font-size: 1.42rem;
            text-transform: capitalize;
            color: var(--cu-white);
        }

        &-card {
            padding: 2.85rem 4.57rem 2.85rem 4.57rem;
        }

        &-image {
            background: var(--cu-black-trans-400);
            align-items: center;
            justify-content: center;
            display: flex;
            margin: 0 auto;
        }

        &-details {
            flex-basis: calc(50% - 1.5rem);

            &:not(:first-child):not(:nth-child(2)) {
                border-top: 1px solid var(--cu-gray-500);
            }

            @media (max-width: 768px) {
                max-width: 100%;
                flex-basis: 100%;

            }
        }

    }
}


ul {
    font-weight: 400;
    font-size: 0.85rem;
    word-break: break-all;
    color: var(--cu-gray-100);

    li {
        word-break: break-all;
        font-weight: 400;
        font-size: 1rem;
        color: var(--cu-white);
    }
}

.details {
    padding-left: 1.5rem;
}

.password {
    &-card {
        padding: 1.71rem 4.57rem 2.85rem 4.85rem;
    }

    &-form-field {
        flex: 1 1 22rem;
    }

    &-current-password {
        flex: 1 1 32rem;

        @media (min-width: 1262px) {
            max-width: calc(50% - 0.75rem);
        }
    }

    &-sub {
        font-size: 0.85rem;
        color: var(--cu-gray-100);
    }
}

.add-more {
    display: flex;
    width: auto;
    background-color: transparent;
    padding: 0px;
    top: -2.14rem;
    position: relative;
    left: 4.35rem;
    font-size: 1.14rem;
    color: var(--cu-blue);
}

.profile-edit {
    &-card {
        padding: 1.71rem 4.57rem 2.14rem 4.57rem;
    }

    &-label {
        max-width: 15.35rem;
    }

    &-head {
        font-weight: 400;
        font-size: 1.28rem;
        color: var(--cu-white);
    }

    &-input {
        .form-box {
            margin-top: 0.71rem;
            font-size: 1.14rem;
        }

        .form-label {
            font-size: 1rem;
            padding-bottom: 0.71rem;
        }
    }

    &-delete {
        background-color: var(--cu-brown);
    }

    &-address {
        flex: 1 1 38.78rem;
    }

    &-form-field {
        flex: 1 1 25.35rem;
        min-width: 25.35rem;
    }

    &-image {
        .upload-image {
            height: 7.14rem;
            overflow: hidden;
            width: 7.14rem;
            border-radius: 50%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
}