@mixin showIcon {
    opacity: 1;
    visibility: visible;
}

@mixin hideIcon {
    opacity: 0;
    visibility: hidden;
}

.sort-icon {
    margin-left: 0.57rem;
    width: .9rem;
    height: 1rem;
    align-items: center;
    justify-content: space-between;
    display: flex;
    cursor: pointer;

    img {
        @include showIcon;
        transition: .5s ease;
    }

    &.showUP {
        .sort-icon-down {
            @include hideIcon;
        }
    }

    &.showDown {
        .sort-icon-up {
            @include hideIcon;
        }
    }
}