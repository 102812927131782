@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.image {


  &-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
      content: '';
      border: 1rem solid;
      border-radius: 50%;
      border-top: 0.42rem solid var(--cu-white);
      border-right: 0.42rem solid var(--cu-gray-960);
      border-bottom: 0.42rem solid var(--cu-white);
      border-left: 0.42rem solid var(--cu-gray-960);
      min-width: 3.57rem;
      min-height: 3.57rem;
      -webkit-animation: spin 2s linear infinite;
      animation: spin 2s linear infinite;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      z-index: 3;
    }

    &:before {
      content: '';
      position: absolute;
      @apply inset-0;
      background: transparent;
      -webkit-backdrop-filter: blur(3px);
      backdrop-filter: blur(3px);
      opacity: 0;
      visibility: hidden;
      z-index: 2;
    }

    &.loader {

      &:after,
      &::before {
        opacity: 1;
        visibility: visible;

      }

    }
  }
}

.loader-image {
  width: 100%;
}