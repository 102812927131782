h1{
    font-size: 2.85rem;
    font-weight: 600;
}
h2{
    font-size: 1.5rem;
    font-weight: 600;
}
h5{
    font-size: 1.125rem;
    font-weight: 600;
}