.subscription-plan {
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.7;

    .title {
        color: #FFF;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .card {
        background-color: #24252e;
    }

    .card-details {
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 2;
    }
}