.view-video {
    height: calc(100% - 2rem);

    iframe {
        height: 100%;
    }

}

.video {
    position: relative;
}
.myiframe {
    &:hover {
        background-color: red;
    }
}