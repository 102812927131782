.sub-admin-management{
    .popup-wrapper{
        @media screen and (max-width: 768px) {
            max-height: max-content !important;
          }
        .popup-body{
            max-height: calc(100vh - 8rem);
            padding-top: 1rem;
        }
    }
    
}