.analytics {
    background-color: var(--cu-black-700);
    flex: 1 1 17.21rem;
    border-radius: 0.57rem;

    &-graph {
        background-color: var(--cu-black-700);
        flex: 1 1 36.14rem;
        border-radius: 0.57rem;
    }

    &-header {
        &-image {
            max-height: 2.5rem;

            img {
                height: 100%;
            }
        }

        &-count {
            span {
                font-size: 1.71rem;
                color: var(--cu-gray-960);
            }

        }
    }

    &-label {
        font-size: 1rem;
        color: var(--cu-black-trans-270);

    }

    &-dropdown {
        height: fit-content
    }

    &-date {
        color: var(--cu-blue);
        font-size: 0.85rem;
    }
}


.graph-range {
    .form-box {
        background: var(--cu-gray-945);
        margin-right: 0.71rem;
        height: 2.5rem;
        margin-top: 0px !important;
        padding: 0.64rem;
        padding-right: 5.5rem;
        width: 7rem;
    }

    .selectbox-placeholder {
        font-size: 0.85rem;
        color: var(--cu-white);
    }

    .selectbox-label::after {
        top: calc(100% - 1.6rem);
        background-image: url(~/src/Assets/Images/GraphDropdown.svg);
        right: 2rem;
    }
}

.analytics-header {
    font-size: 0.85rem;
    color: var(--cu-green);

    span {
        color: var(--cu-gray-960);
    }
}


.graph-range {
    .selectbox-list ul {
        background: var(--cu-gray-945);
    }
}




.analytics-template {
    .popup-template {
        top: 6rem;
        right: 6rem;
        bottom: 0px;
        left: auto;

        @media (max-width: 641px) {
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
        }
    }
}