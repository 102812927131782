.access-plan {
    &-card {
        padding: 0.85rem 0.85rem 0px 0.85rem;
    }

    &-title {
        font-size: 1.28rem;
        color: var(--cu-white);

    }

    &-sub-head {
        font-size: 1rem;
        color: var(--cu-white);
    }

    &-edit {
        &-form-field {
            flex: 1 1 21.2rem;
        }
        &-card{
            background: var(--cu-black-700);
            width: 100%;
            border-radius: 0.42rem;
        }
    }

    &-content {
        font-weight: 400;
        font-size: 1rem;
        color: var(--cu-gray-100);
        word-break: break-all;
    }

    &-text {
        flex: 1 1 46.07rem;
    }

    &-image {
        flex-basis: 11.21rem;
        width: 11.21rem;
        margin: 0 auto;

        img {
            width: 100%;
        }
    }

    &-add {
        text-align-last: right;
    }

    &-button {
        width: auto;
    }
}

.access-image {

    .upload-image {
        flex-basis: 11.85rem;
    }

    img {
        width: 100%;
        padding: 0.85rem 0.85rem;
    }
}

.access-plan {
    .textArea {
        height: fit-content;
        resize: none;
    }
}
