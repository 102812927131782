.gallery {
    &-details {
        background: var(--cu-black-700);
        padding: 1.71rem;

        &-image {
            max-width: 6.5rem;
            min-height: 6.5rem;
            word-break: break-all;
            max-height: 6.5rem;

            img {
                width: 100%;
            }
        }

        &-title {
            font-size: 1.42rem;
            color: var(--cu-white);
        }

        &-name {
            font-size: 1rem;
            color: var(--cu-gray-100);
        }

        &-value {
            font-size: 0.85rem;
            color: var(--cu-white);
            text-transform: capitalize;
        }

        &-box {
            min-width: 8.57rem;
            border-right: 1px solid var(--cu-gray-900);
        }

        &-card {
            background: var(--cu-black-700);
            padding: 2.07rem 1rem 2.07rem 1rem;
            border-radius: 0.57rem;
            min-height: calc(100% - 0rem);

            &-title {
                font-size: 1rem;
                color: var(--cu-white);
            }
        }
    }
}

.gallery-views {
    padding: 2rem 2.78rem 2rem 2.78rem;
    flex: 1 1;

    span {
        font-size: 1.71rem;
        color: var(--cu-white);
        text-transform: capitalize;
    }

    a {
        color: var(--cu-blue);
    }

    &-border {
        border-bottom: 1px solid var(--cu-gray-900);
    }

    &-card {
        flex: 1 1 11.42rem;
        min-height: 4.85rem;
        background: var(--cu-black-trans-300);
        border-radius: 0.35rem;
        padding: 0.92rem;
        color: var(--cu-gray-100);
        word-break: break-all;

        p {
            font-size: 0.85rem;
            opacity: 0.6;
        }

        span {
            font-size: 1rem;
            color: var(--cu-gray-100);
        }
    }

    &-desc {
        font-size: 1rem;
        color: var(--cu-white);
        font-style: normal;
        opacity: 0.9;
    }

    &-image {
        background: var(--cu-black-trans-300);
        padding: 6.28rem 3.21rem 6.28rem 3.35rem;
        max-height: calc(100vh - 7rem);

        img {
            min-width: 12.78rem;
            max-width: 15.07rem;
            width: 100%;
            border-radius: 50%;
        }

        ul {
            padding-top: 1.3rem;
            font-size: 0.85rem;
            color: var(--cu-gray-100);

            li {
                padding-top: 0.4rem;
                font-size: 0.92rem;
                color: var(--cu-white);
                opacity: 0.8;
            }

            .name {
                font-size: 1.14rem;
                color: var(--cu-white);
            }
        }
    }

    &-images {
        width: 100%;
    }

    &-value {
        font-size: 1rem;
        color: var(--cu-gray-100);
        text-transform: capitalize;
    }

    &-full-card {
        max-height: 24.42rem;
        overflow-y: scroll;
    }

}

.gallery-view-popup .popup-template {
    background-color: var(--cu-black-700);
}

.gallery-view-popup .popup-template-body {
    max-height: calc(100vh - 7rem);
    overflow: hidden;
}

.desc {
    min-width: 100%;
}

.gallery-wallet-profile .user {
    width: 12.78rem;
    height: 12.78rem
}

.nft-status {
    display: flex;
    justify-content: center;
    align-items: center;
}

.gallery-link {
    a {
        color: var(--cu-blue);
    }
}