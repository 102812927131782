.video {
    &-label {
        font-size: 1.14rem;
    }

    &-wrapper {
        position: relative;
        overflow: hidden;

        img {
            width: 100%;
        }
    }

    &-mapper {
        overflow: hidden;
        font-size: .8rem;
        display: flex !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: var(--cu-white-trans-200);
        z-index: 5;
        &:after{
            content: '';
            left: .4rem;
            right: .4rem;
            top: .4rem;
            bottom: .4rem;
            position: absolute;
            z-index: 1;
        }

        h4 {
            font-size: 1.4em;
            color: var(--cu-brown);
            text-shadow: 0 0 var(--cu-black);
            font-weight: 600;
        }
    }
}

.content-menu {
    position: relative;
}
.preview-section {
    .video-mapper {
        &:after{ 
            position: unset;
            content: unset;
        }
    }
}