.collection-terms-container {
    background: var(--cu-black-600);

    .collection-terms-table {
        td, th {        
            font-size: 0.85714rem;
            line-height: 0.85714rem;
        }
        thead tr, tbody tr {
            border-bottom: 0.5px solid var(--cu-black-trans-250);
        }
        tbody th {
            text-align: left;
        }
        td, th {
            padding: 1rem;
        }
        tbody td, thead th:not(:first-child) {
            text-align: center;
        }
        thead th {
            color: var(--cu-black-10);
        }
        tbody td, tbody th {
            color: var(--cu-gray-980);
            font-weight: 600;
        }
        .row-head {
            gap: 1rem;
            padding-left: 0;;
        }
        .round  {
            label {
                background-color: var(--cu-white-900);
                border: 1px solid #ccc;
                border-radius: 50%;
                cursor: pointer;
                height: 1.4rem;
                left: 50%;
                position: absolute;
                top: 0;
                width: 1.4rem;
                max-height: 7rem;
                padding: 0.425rem;
            }
            &.overall-round {
                label {
                    height: 1rem;
                    width: 1rem;
                    &:after  {
                        left: 1px;
                        top: 2px;
                        width: 0.7rem;
                        height: 0.4rem;
                    }
                }
            }
        } 
        .round label:after {
            border: 2px solid var(--cu-black-700);
            border-top: none;
            border-right: none;
            content: "";
            height: 0.4rem;
            left: 4px;
            opacity: 0;
            position: absolute;
            top: 5px;
            transform: rotate(-45deg);
            width: 0.8rem;
        }
      
        .round input[type="checkbox"], .round input[type="radio"] {
            visibility: hidden;
        }
      
        .round input[type="checkbox"]:checked + label,
        .round input[type="radio"]:checked + label {
            background-color: var(--cu-white-100);
            border-color: var(--cu-white-100);
        }
      
        .round input[type="checkbox"]:checked + label:after,
        .round input[type="radio"]:checked + label:after {
            opacity: 1;
        }
    }
    .terms-details {
        .text-head  {
            span {
                font-size: 0.86rem;
                color: var(--cu-black-10);
                font-weight: 600;
            }
        }
        .text-body  {
            flex: 1;
            span {
                font-size: 0.86rem;
                font-weight: 400;
                text-align: justify;    
                color: var(--cu-gray-980)            
            }
        }
    }
}