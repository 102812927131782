.pagination {
    ul {
        display: flex;
        gap: 0.57rem;
        li {
            flex: 1 1 3.33rem;
            min-width: 3.33rem;
            color: var(--cu-white);
            font-size: 1rem;
            line-height: 1.21rem;
            display: flex;
            justify-content: center;
            align-items: center;

            &.selected {
                color: var(--cu-blue-150);
            }
        }
    }

    .previous,
    .next {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-left,
    &-right {
        display: block;
        width: 3.4rem;
        height: 3.4rem;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        opacity: 0.3;
    }

    &-left {
        background-image: url(~/src/Assets/Images/pagination-left-active.svg);
    }

    &-right {
        background-image: url(~/src/Assets/Images/pagination-right-active.svg);
    }

    &-total {
        font-weight: 500;
        color: var(--at-gray-810);
    }

    .previous {
        &.disabled {
            .pagination-left {
                background-image: url(~/src/Assets/Images/pagination-left-inactive.svg);
            }
        }
    }

    .next {
        &.disabled {
            .pagination-right {
                background-image: url(~/src/Assets/Images/pagination-right-inactive.svg);
            }
        }
    }
}

span.pagination-break::before{
        content:'. . .'
    }
