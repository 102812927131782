$space-left: 2.85rem;
$space-right: 5.71rem;
$space-mobile: 2rem;
@mixin border-radius {
    border-radius: 0.71rem;
}

@mixin primeButton {
    border-radius: 0.57rem;
    font-size: 1rem;
    padding: .8rem 2.14rem;
}

@mixin backgroundfull {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

@mixin scroll {
    overflow: auto;
    scroll-behavior: smooth;
}

@mixin hr-border($type) {
    @if($type == bottom) {
        border-bottom: 1px solid var(--cu-gray-trans-200);
    }
    @if($type == right) {
        border-right: 1px solid var(--cu-gray-trans-200);
    }
}
@mixin padding-left-fullWrapper {
    padding-left: $space-mobile;
    @screen md{
        padding-left: $space-left;
    }
}

@mixin padding-right-fullWrapper {
    padding-right: $space-mobile;
    @screen md{
        padding-right: $space-right;
    }
}
@mixin margin-left-fullWrapper {
    margin-left: -($space-mobile);
    @screen md{
        margin-left: -($space-left);
    }
}

@mixin margin-right-fullWrapper {
    margin-right: -($space-mobile);
    @screen md{
        margin-right: -($space-right);
    }
}
@mixin left-fullWrapper {
    left: ($space-mobile);
    @screen md{
        left: ($space-left);
    }
}

@mixin right-fullWrapper {
    right: ($space-mobile);
    @screen md{
        right: ($space-right);
    }
}
@mixin dropdownShadow {
    box-shadow: 0px 6px 4px 1px rgb(0 0 0 / 20%);
}