.frame-preview {
    &-outer {
        position: relative;
        background-size: 100% 100%;
        overflow: hidden;
        background-color: var(--cu-green-100);

    }

    &-image {
        position: absolute;
        transition: all .2s linear;
        background-color: var(--cu-blue);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 90%;
        // background-image: url(~/src/Assets/Images/logo.svg);        
    }
}

.frame-toggle-image {
    position: relative;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 9;
    height: 100%;
    width: 100%;
    object-fit: contain;
    //height: 12.25rem !important;

    // padding: 1rem;
}

.frame-image-on {
    z-index: 9;
}

.frame-image-off {
    z-index: 0;
}

.test {
    width: 263px;
    height: 168px;
    background: #11141C;
    border-radius: 15px;
}

.name {
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    text-align: center;
    letter-spacing: 0.0666667px;

    color: #41444F;
}

.hy {
    max-height: 13.75rem;
    height: 8.5rem !important;
}

.testing {


    padding: 1rem 4rem;
}