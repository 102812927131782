.custome-datetimepicker {
    .datetimepickerContainer  {
        background: var(--cu-gray-850);
        height: 3.28rem !important;
        border-radius: 0.35rem;
        font-weight: 400;
        font-size: 1rem;
        color: var(--cu-white);
        width: 100%;
        outline: none;
        .ep-arrow.close-arrow {
            display: none;
        }
        .rmdp-time-picker div input {
            width: 30px;
            height: 30px;
            background: var(--cu-gray-850);
            font-weight: inherit;
        }
        .rmdp-time-picker div {
            gap: 1rem;
        }
    }
}