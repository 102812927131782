@import '~/src/Styles/abstracts/mixins';
$time: .2s;

.form-box {
    font-size: 1rem;
}

.formControl,
.autoCom ul>li {
    padding-left: 1rem;
    padding-right: 1rem;
}

.autoCom {
    &-list {
        top: 100%;
        left: 0;
        right: 0;
        z-index: 9;
        // transition: $time ease;
        overflow: hidden;
        max-height: 0;

        ul {
            margin-top: 0.21rem;
            border: 1px solid var(--cu-gray-850);
            border-radius: 0.25rem;
            background: var(--cu-gray-850);
            padding: 0;
            overflow: auto;
            max-height: 13rem;
            margin-bottom: 0.5rem;

            li {
                padding-top: .5rem;
                padding-bottom: .5rem;
                cursor: pointer;
                &:is(:hover,.active){
                    background: var(--cu-gray-1000);
                }
            }
        }

        &-closed {
            .autoCom-list {
                // animation: fadeOut $time;
                box-shadow: 0px 0px 0px 0px;
            }
        }

        &-open {
            .autoCom-list {
                // animation: fadeIn $time;
            }
        }
    }
}