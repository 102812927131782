.multiselect {
    &-group {
        display: flex;
    }

    &-checkbox {
        border: 1px solid;
        width: 1.3rem;
        height: 1.3rem;
        position: relative;

        &-checked {
            position: absolute;
            @apply inset-0;
            background-image: url(~/src/Assets/Images/tick.png);
            background-size:cover;
        }
    }
}