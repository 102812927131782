.cu-table {
    &-responsive {
        @include scroll;
        white-space: nowrap;
    }

    table {
        width: 100%;

        thead {
            background: var(--cu-gray-850);

            th {
                font-weight: 600;
                padding-top: 1.64rem;
                padding-bottom: 1.64rem;


            }
        }

        th,
        td {
            color: var(--cu-gray-100);
            padding-left: 1rem;
            padding-right: 1rem;
        }

        td {

            @include hr-border(bottom);
            padding-top: 1.42rem;
            padding-bottom: 1.42rem;
        }
    }
}