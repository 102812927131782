.image-upload {
    border-radius: 50%;
    overflow: hidden;
}

.upload {
    color: var(--cu-blue);
    text-decoration: underline;
    font-size: 1.14rem;
    position: relative;
    overflow: hidden;
}

.image-label{
    position: absolute;
    @apply inset-0;
    opacity: 0;
}