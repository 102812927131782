.status-button {
    color: var(--cu-white);
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.21rem;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    padding: 1rem 2.14rem 1rem 2.14rem;
    border-radius: 0.57rem;

    &-approve {
        background: var(--cu-green);

    }

    &-reject {
        background: var(--cu-brown);
    }


}

.button {
    padding: 0.42rem 0.57rem 0.42rem 0.57rem;
    font-size: 0.85rem;
    line-height: 0.85rem;
    border-radius: 0.42rem;

    &-approved {
        border: 1px solid var(--cu-green-100);
        color: var(--cu-green-100);
    }

    &-rejected {
        border: 1px solid var(--cu-brown);
        color: var(--cu-brown);
    }
}

.no-pointer{
    pointer-events: none;
}