.collection {
    table {
      td:not(:first-child),td .name-span {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      tbody {
        tr {
          cursor: default !important;
        }
      }
    }
  }