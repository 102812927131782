.wallet {
    &-button {
        width: auto;
    }

    &-label {
        font-size: 0.85rem;
        line-height: 1.14rem;
    }
}

.nodata {
    color: var(--cu-gray-100);
    font-size: 1.3rem;
}

.date-desk .popup-template {
    max-width: fit-content;
    max-height: max-content;
}