.wall-total {
    color: var(--cu-white);
    min-width: 9rem;

    div {
        font-size: 1rem;
        color: var(--cu-gray-100);

        span {
            color: var(--cu-white);
        }
    }
}

.wall-body {
    img.list-tickimage {
        width: 20px;
        display: block;
        margin: auto;
    }
}

.default-column {
    width: 5rem;
}

.wall-body:hover {
    background-color: var(--cu-gray-940);
    cursor: pointer;

    .wall-image {
        img {
            opacity: 0.4
        }
    }

    .wall-eye {
        position: absolute;
        justify-content: center;
        align-items: center;
        display: flex;
        background: var(--cu-blue);
        border-radius: 0.2rem;

        .btn-eye {
            height: 0.8rem;
            width: 1rem;
        }

        .btn-eye-on {
            opacity: 1;
            margin: 0.3rem;
            padding: 0px;
        }
    }
}

.wall {
    &-eye {
        display: none;
    }

    &-image {
        max-width: 6rem;
        max-height: 5rem;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .add-image {
        flex-basis: 46.35rem;
        padding: 2.21rem;

        img {
            object-fit: cover;
        }

        &-frame {
            width: 100%;
            padding: 0px;
        }
    }

    &-form {
        background: var(--cu-black-700);
        padding: 2.21rem;
        height: fit-content;
        border-radius: 0.57rem;
    }

    &-view {
        padding: 2.42rem 2rem;
    }

    &-upload {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;

        .upload-image {
            width: 100%;

            .image-container {
                width: 100%;
            }

            img {
                width: 100%;
            }
        }
    }


}

.delete-wall {
    .user {
        width: 11.4rem;
        overflow: hidden;
        border-radius: 0%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 7rem;

        &-name {
            font-size: 1.42rem;
            font-weight: 500;
            color: var(--cu-white);
            text-transform: capitalize;
            word-break: break-all;
        }

        &-outer {
            gap: 1.71rem;
        }
    }
}

.wall-view {
    span {
        font-size: 1.14rem;
        text-transform: capitalize;
        color: var(--cu-white);
    }

    img {
        width: 100%;
        max-height: calc(100vh - 16rem);
        object-fit: cover;
    }
}

.wall3d {
    &-content {
        font-size: 1.14rem;
        text-transform: capitalize;
        color: var(--cu-gray-100);
    }

    &-image {
        .loader-image {
            border-radius: 0.35rem;
        }
    }
}
.background-gallery-wall{
.breadcrumbs-layout {
    width: 13.5rem;
}
}
