.sidebar {
    &-overlay {
        position: fixed;
        @apply inset-0;
        background: var(--cu-black-trans-600);
        z-index: 10;
        opacity: 0;
        visibility: hidden;
    }

    &-overlay,
    &-wrapper {
        transition: all .5s ease;
    }

    &-wrapper {
        display: flex;
        flex-direction: column;
        padding: 3.57rem;
        position: fixed;
        top: 0;
        bottom: 0;
        width: 100%;
        max-width: 39em;
        background: var(--cu-black-700);
        z-index: 11;

        &.leftSide {
            left: 0;
            border-radius: 0 2.14rem 2.14rem 0;
            transform: translateX(-100%);
        }

        &.rightSide {
            right: 0;
            border-radius: 2.14rem 0px 0px 2.14rem;
            transform: translateX(100%);
        }

    }

    &.sidepanelActive {
        .sidebar-overlay {
            opacity: 1;
            visibility: visible;
        }

        .sidebar-wrapper {
            transform: translateX(0);
        }
    }

    &-title {
        font-size: 1.42rem;
        font-weight: 600;
        color: var(--cu-white);
    }

    &-header {
        padding-bottom: 1rem;
    }

    &-body {

        padding-top: 2.57rem;
        @include scroll;
    }
}