.wallet-profile {
    .user {
        width: 7.21rem;
        border-radius: 50%;
        overflow: hidden;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height:7rem;
        &-name {
            font-size: 1.71rem;
            font-weight: 500;
            color: var(--cu-white);
            text-transform: capitalize;
        }

        &-desc {
            font-size: 1.14rem;
        }

        &-outer {
            gap: 1.71rem;
        }
    }
}

.user {
    width: 2.24rem;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    &-outer {
        gap: 0.57rem;
    }
}

.user-table {
    .user {
        max-width: 2.24rem;
        height: 2.24rem;
        border-radius: 50%;
        overflow: hidden;
    }
}

.block-user{

    .user{
        width: 7.21rem;
        min-height: 7.21rem;
    }
    &-card{
        padding: 2rem 2rem 2rem 2rem;
        font-size: 1.14rem;
        color: var(--cu-gray-100);
    }
}
