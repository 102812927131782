.user {

    &-form {
        flex: 1;
        max-width: 21.4rem;

        @media (max-width: 768px) {
            flex-basis: 100%;
            max-width: 100%;

        }

        input[type=text]:focus {
            outline: none;
        }

        input[type=text]:focus {
            border: 1px solid var(--cu-white);
        }
    }

}

.user-create-pop {
    .popup-wrapper {
        @media (max-width: 768px) {
            max-height: 36rem !important;
        }
    }

}