.rewards-main{
    .regWrp{
        display: flex;
        flex-direction: column;
        gap: 30px;

        .title {
            color: #FFF;
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }
 
}