.switch {
    position: relative;
    display: inline-block;
    width: 3.21rem;
    height: 1.35rem;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--cu-gray-850);
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 1.07rem;
    width: 1.07rem;
    left: 0.21rem;
    top: 0.14rem;
    bottom: 0.28rem;
    background-color: var(--cu-white);
    -webkit-transition: .4s;
    transition: .4s;

}

input:checked+.slider {
    background-color: var(--cu-green);
}


input:checked+.slider:before {
    -webkit-transform: translateX(1.85rem);
    -ms-transform: translateX(1.85rem);
    transform: translateX(1.85rem);
}

.slider.round {
    border-radius: 2.42rem;
}

.slider.round:before {
    border-radius: 50%;
}

.gallery-switch {
    .switch {
        width: 2.35rem;
        height: 1.35rem;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 0.9rem;
        width: 0.9rem;
        left: 0.3rem;
        top: 0.2rem;
        background-color: var(--cu-white);
        -webkit-transition: .4s;
        transition: .4s;

        @media (max-width: 1018px) {
            height: 1rem;
            width: 1rem;
        }

    }

    input:checked+.slider:before {
        -webkit-transform: translateX(1.2rem);
        -ms-transform: translateX(1.2rem);
        transform: translateX(1.2rem);
        left: 0.1rem;
    }
}