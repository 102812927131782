.policy-profile {
    .user {
        width: 4.21rem;
        height: 4.21rem;
        border-radius: 50%;
        overflow: hidden;

        &-name {
            font-weight: 500;
            font-size: 1.14rem;
            color: var(--cu-white);
        }

        &-desc {
            font-size: 0.85rem;
            color: var(--cu-gray-100);
            word-break: break-all;
            margin-right: 0.35rem;
        }
    }
}


.policy {
    &-details {
        flex: 1 1 13.5rem;
        padding-left: 1.85rem;

        &:not(:first-child) {
            border-left: 1px solid var(--cu-gray-500);
        }

        @media (max-width: 768px) {

            &:first-child {
                flex: 1 1 20rem;
            }
        }

        &-label {
            font-size: 1rem;
            line-height: 1.21rem;
            color: var(--cu-gray-970);
        }

        &-value {
            font-size: 1rem;
            line-height: 1.21rem;
            flex: 1;
            word-break: break-all;
            color: var(--cu-white);
        }

        &-divider {
            width: 100%;
            opacity: 0.16;
            border: 1px solid var(--cu-gray-500);
        }

        &-card-height {
            height: 6.07rem;
        }

        &-first-card {
            max-width: 38rem;
            flex-basis: 38rem;
        }

        &-second-card {
            max-width: 29.4rem;
            flex-basis: 29.4rem;
        }
    }

    &-popup {
        &-label {
            font-weight: 400;
            font-size: 1.14rem;
            color: var(--cu-gray-970);
        }


        &-value {
            font-weight: 400;
            font-size: 1.14rem;
            word-break: break-all;
            color: var(--cu-white);
        }
    }
}

@media (max-width: 768px) {
    .policy-mobile {
        padding-top: 1rem;
    }
}

.full-card {
    padding: 0.28rem 0.92rem 0.28rem 0.28rem;
}