.edit-collection {
  .popup-wrapper {
    max-width: 80%;
    max-height: 80%;
    .content {
      white-space: pre-wrap;
      font-size: 1rem;      
    }
  }
  .collection {
    h2 {
      font-size: 1.375rem;
      font-weight: 600;
    }
    .section-title {
      font-size: 1rem;
      font-weight: 400;
      color: var(--cu-white);
    }
    .error-validation {
      font-size: 0.625rem;
    }
    .brd-btm {
      border-bottom: 1px solid var(--cu-black-100);
      padding-bottom: 0.625rem;
    }
    label {
      font-size: 0.625rem;
      font-weight: 400;
      color: var(--cu-white-100);
    }
    :is(input, textarea)::placeholder {
      color: var(--cu-black-10);
    }
    .form-box {
      max-height: 2rem;
      font-size: 0.625rem;
    }
    .selectbox-list {
      ul {
        li {
          font-size: 0.625rem;
        }
      }
    }
    .selectbox-label::after {
      width: 0.625rem;
      height: 0.625rem;
    }
    input,
    textarea {
      background-color: var(--cu-black-700);
      border: 1px solid var(--cu-black-100);
      color: var(--cu-white-200);
      font-size: 0.625rem;
      max-height: 7rem;
      padding: 0.425rem;
      border-radius: 4px;
      &::placeholder {
        color: var(--cu-black-10);
      }
    }
    :is(input, textarea)::placeholder {
      color: var(--cu-black-10);
    }
    textarea {
      max-height: 15rem;
    }
    .formControl,
    .autoCom ul>li {
       font-size: 0.625rem;
       color:var(--cu-white-200);
    }
    .upload-banner-image {
      background: var(--cu-black-700);
      border: 1px solid var(--cu-black-100);
      border-radius: 4px;
      height: 10.25rem;
      label {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
      }
      .upload-icon {
        display: none;
        background-color: var(--cu-black-700);
        margin: auto;
      }
      .dummy-wrap {
        p {
          text-align: center;
          font-size: 0.75rem;
          color: var(--cu-black-10);
        }
      }
      .image-uploaded {
        padding: 0px;
        margin: auto;
        overflow: hidden;
        object-fit: contain;
        max-height: 10.25rem;
        height: 100%;
        &.actual-img {
          width: 100%;
          object-fit: initial;
        }
      }
      &.profile-img {
        width: 10.375rem;
        height: 10.25rem;
        .image-uploaded.actual-img {
          max-height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }
      &:hover {
        .upload-icon {
          display: inline;
        }
        .dummy-wrap,
        .image-uploaded {
          display: none;
        }
      }
    }
    .new-checkbox span {
      background: var(--cu-black-600);
      border: 1px solid var(--cu-black-100);
      border-radius: 4px;
      color: var(--cu-black-10);
      padding: 0.2rem 0.625rem;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
      &.active {
        border-color: var(--cu-blue-60);
      }
    }
    button {
      padding: 0.325rem 2.14rem;
      font-size: 0.825rem;
    }
    &.create {
      h3 {
        font-size: 1.375rem;
        font-weight: 600;
      }
      textarea {
        max-height: 33.5rem;
        resize: none;
        @media screen and (max-width: 550px) {
          max-height: 7.5rem;
        }
      }
      .new-checkbox span {
        color: var(--cu-white-900);
      }
      .sub-title {
        font-size: 0.75rem;
      }
    }
    @media screen and (max-width: 550px) {
      &.create {
        .button {
          width: 100%;
        }
      }
    }
    .foot-text {
      font-size: 1rem;
      color: var(--cu-black-5);
      line-height: 1.21429rem;
    }
  }

  h2 {
    font-size: 1.375rem;
    font-weight: 600;
    color: var(--cu-white);
  }
  .banner {
    flex-wrap: wrap;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    gap: 1.06rem;
    .image-section {
      grid-template-columns: repeat(auto-fill, minmax(15.5rem, 1fr));
      gap: 1.06rem;
      max-width: 32.75rem;
      @media (max-width: 768px) {
        max-width: 100%;
        grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
      }
      .img-box {
        border-radius: 0.25rem;
        border: 1px solid var(--cu-black-100);
        background: var(--cu-black-600);
        box-shadow: 0px 0.375rem 0.75rem 0px rgba(0, 0, 0, 0.1);
        height: 7.3rem;
        cursor: pointer;
        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
    }
    .details-section {
      border-radius: 0.375rem;
      border: 1px solid var(--cu-black-100);
      background: var(--cu-black-900);
      padding: 1.375rem;
      gap: 0.625rem;
      display: flex;
      flex-direction: column;
      flex: 1;
      min-width: 250px;
      .reload-section {
        width: 100%;
        height: 0.8125rem;
      }
      .details-box {
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        .title {
          color: var(--cu-blue-70);
          font-size: 0.875rem;
          font-weight: 600;
          min-width: 7.5rem;
        }
        .content {
          color: var(--cu-white-200);
          font-size: 0.875rem;
          font-weight: 400;
          overflow: hidden;
          text-overflow: ellipsis;
          @media (max-width: 768px) {
            max-width: 8.75rem;
          }
        }
      }
    }
  }
  .community-list-container {
    .item-container {
      display: flex;
      flex-direction: column;
      gap: 0.71429rem;
      color: #fff;
      &.active {
        padding: 10px;
        border: 2px solid;
        border-radius: 4px;
      }

      .image-container {
        border-radius: 6.57143rem;
        object-fit: cover;

        img {
          width: 6.57143rem;
          height: 6.57143rem;
          border-radius: 6.57143rem;
        }
      }

      .title-container {
        font-weight: 500;
        font-size: 1rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 5.75rem;
        line-height: 0.85714rem;
      }
    }
  }
}

.savetag {
  width: 8rem;
  margin-left: 1rem;
  padding: 0 !important;
  height: 1.8rem;
  margin-top: 0.3rem;
  border-radius: 0.35rem;
}
