html,body,#root{
    height: 100vh;
    @include scroll;
    background: var(--cu-black-800);
}
body,html{
    font-family: Poppins;
    font-size: 10px;
    @screen md{
        font-size: 12px;
    }    
    @screen lg{
        font-size: 14px;
    }
    @screen 2xl{
        font-size: 16px;
    }    
 
}