.multiselect-filter {
    &-label {
        font-size: 1rem;
        color: var(--cu-gray-100);
        width: 100%;
        display: flex;
        gap: 0.64rem;

    }
}

.multiselect {
    display: block;
    position: relative;
    padding-left: 2.18rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.multiselect input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.18rem;
    width: 1.18rem;
    background-color: transparent;
    border: 1px solid var(--cu-gray-100);
    border-radius: 0.25rem;
}

.multiselect input:checked~.checkmark {
    background-color: var(--cu-blue);
    border: 0px;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.multiselect input:checked~.checkmark:after {
    display: block;
}

.multiselect .checkmark:after {
    position: absolute;
    @apply inset-0;
    background-image: url(~/src/Assets/Images/tick.png);
    background-size: cover;

}