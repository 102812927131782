.select-filter {
    &-head {
        font-size: 1.28rem;
        color: var(--cu-white);
    }
    .selectbox-list-open {
        .selectbox-list {
            max-height: 8rem !important;
            overflow: scroll;
            overflow-x: hidden;
        }
    }
}

input[type="checkbox"]:checked {
    accent-color: var(--cu-blue);


}

input[type="checkbox"] {
    width: 1.1rem;
    height: 1.1rem;
}