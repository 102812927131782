  .range-slider-container {
    .range-slider {
      height: 2px;
    }
    .range-slider .range-slider__thumb {
      height: 15px;
      width: 15px;
    }
    .rang-values {
      span {
          color: var(--cu-gray-100);
      }
    }
  }
  