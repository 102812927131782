.add-project {
    flex: 0 0 50%;
    background: var(--cu-black-700);
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 50%;
    .project-form-datepicker {
        .datetimepickerContainer {
            margin-top: 0.25rem;
        }
    }
}