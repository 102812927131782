::-webkit-scrollbar {
    width: 20px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 100px;
}

::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 5px solid transparent;
    background-clip: content-box;
    -webkit-background-clip: content-box;
    background-color: var(--cu-blue);
}