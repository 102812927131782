.input {
    &-login {
        @include border-radius;
        background: var(--cu-gray-800);
        padding: 1.14rem 1.85rem 1.14rem 1.71rem;

        label {
            display: block;
            color: var(--cu-gray-100);
            font-size: 0.85rem;
        }

        input[type="text"],
        input[type="password"] {
            width: 100%;
            background: none;
            border: none;
            outline: none;
            color: var(--cu-white);
        }

        &-group {
            flex: 1;
            padding-right: 1rem;
        }

        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

input[type="text"],
input[type="password"],
input[type="number"] {
    &.formControl {
        width: 100%;
        background: none;
        border: none;
        outline: none;
        color: var(--cu-white);
        height: 100%;
        -webkit-box-shadow: 0 0 0 1000px var(--cu-gray-850) inset !important;
    }
}
:is(input, textarea)::placeholder {
    color: var(--cu-black-10);
}
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    -webkit-text-fill-color: var(--cu-white);
    -webkit-box-shadow: 0 0 0 1000px var(--cu-gray-850) inset !important;
}

.form {
    &-label {
        font-weight: 500;
        font-size: 1.14rem;
        color: var(--cu-gray-100);
    }

    &-box {
        background: var(--cu-gray-850);
        height: 3.28rem;
        border-radius: 0.35rem;
        font-weight: 400;
        font-size: 1.28rem;
        color: var(--cu-white);
        width: 100%;
        outline: none;
        overflow: hidden;
    }

    &-value {
        font-size: 1rem;
        color: var(--cu-gray-500);

        &-pre {
            margin-left: 0.5rem;
        }

        &-post {
            margin-right: 0.5rem;
        }
    }
}

.form-box:focus-within {
    border: 1px solid var(--cu-white);
}

.error-validation {
    p {
        color: red;
        margin: .2rem 0;
    }
}

input[type="file"] {
    cursor: pointer;
}