.pdf-upload {
    background: var(--cu-gray-850);
    margin-top: 2rem;
    border-radius: 4px;

}

.pdf-img {
    height: 3rem;
    border-radius: 4px;

}

.filename {
    color: var(--cu-white);
    word-break: break-all;
}