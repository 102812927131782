.date-picker {
    background-color: var(--cu-black-700);
    border-radius: 0.57rem;
    width: fit-content;
}

.rmdp-wrapper {
    background-color: var(--cu-black-700) !important;
    border-radius: 0.35rem !important;
}

.rmdp-range {
    background-color: var(--cu-gray-930) !important;
    box-shadow: 0px 0px 0px !important;
    color: var(--cu-white) !important;
}

.rmdp-day.rmdp-today span {
    background-color: transparent !important;
    color: var(--cu-gray-960) !important;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
    background-color: var(--cu-blue) !important;
    color: var(--cu-white) !important;
}

.rmdp-week-day {
    color: var(--cu-gray-960) !important;
}

.rmdp-day span {
    color: var(--cu-gray-960) !important;
}

.rmdp-month-picker,
.rmdp-year-picker {
    background-color: var(--cu-black-700) !important;
}

.rmdp-border-bottom {
    border-bottom: 0px !important;
}

.rmdp-header-values {
    margin: auto !important;
    color: var(--cu-white) !important;
}

.rmdp-shadow {
    box-shadow: 0 0 0px !important;
}

.rmdp-arrow-container:hover {
    background-color: transparent !important;
    box-shadow: 0 0 0px !important;
}

.rmdp-arrow-container {
    height: 2rem !important;
    width: 2rem !important;
    border: 0.05rem solid var(--cu-black-trans-250) !important;
    border-radius: 0.57rem !important;
}

.rmdp-arrow {
    border: solid var(--cu-blue-100) !important;
    border-width: 0 0.14rem 0.14rem 0 !important;
    margin-top: 0.71rem !important;
}

.rmdp-calendar {
    height: max-content !important;
    padding: 0.64rem !important;
}

.rmdp-range.start,
.rmdp-range.end {
    background-color: var(--cu-blue) !important;

    .sd {
        color: var(--cu-white) !important;
    }
}

.rmdp-day-picker {
    padding: 0.64rem !important;
    gap: 2.07rem !important;
}