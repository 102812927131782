.dropdown-menu {
    &-list {
        position: fixed;
        z-index: 11;
        background: var(--cu-black-700);
        border-radius: 0.35rem;
        ul {
            padding: 1.21rem 1.42rem;
            display: flex;
            flex-direction: column;
            gap: 1.42rem;
            min-width: 7.5rem;
            position: relative;
            &::after{
                position: absolute;
                content: '';
                background-color: var(--cu-black-700);
                clip-path: polygon(50% 0%,55% 0%, 100% 100%, 0% 100%);
                width: 1rem;
                height: 1rem; 
                top: -0.42rem;
                right: 0.42rem;               
            }
            li {
                cursor: pointer;
            }

            &-icon {
                width: 0.85rem;
            }

            &-label {
                font-size: 0.85rem;
            }
        }
    }

    &-overlay {
        position: fixed;
        @apply inset-0;
        z-index: 10;
    }
}