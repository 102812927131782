.spinner{
    position: fixed;
    display: flex;
    @apply inset-0;
    align-items: center;
    justify-content: center;
    background: var(--cu-black-trans-500);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(5px);
    z-index: 13;
}