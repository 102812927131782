
input[type="checkbox"]:checked {
    accent-color: var(--cu-blue);


}

input[type="checkbox"] {
    width: 1.1rem;
    height: 1.1rem;
}