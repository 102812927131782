
.monet-reward-lineitem {
    border-radius: 20px;
    background: var(--cu-gray-850);
    padding: 40px 0;
    color: var(--cu-white);
    .flex {
        display: flex;
    }
    .flex1 {
        flex: 1;
        padding: 0px 40px 
    }
    .flex3 {
        flex: 3;
        padding: 0px 40px 
    }
    .flex-end{
        justify-content: flex-end;
    }
    .borderRightGrey{
        border-right: 1px solid var(--cu-black-10);
    }
    .fd-col{
        flex-direction: column;
    }
    .btnStyle {
        border: 1px solid var(--cu-white);
        padding: 0px 20px;
        border-radius: 8px;
    }
    .iconStyle{
        width: 18px; height: 24px;
    }
    .gap5{
        gap: 5px
    }
    
}