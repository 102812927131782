.svg {
    font-style: italic;
    font-weight: 400;
    font-size: 1rem;
    color: var(--cu-gray-900);
}

.image {
    &-label {
        font-size: 1.14rem;
    }

    &-wrapper {
        position: relative;
        background-color: var(--cu-green-100);
        overflow: hidden;
    }

    &-mapper {
        overflow: hidden;
        font-size: .8rem;
        padding: 1rem;
        display: flex !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 2px solid var(--cu-red);
        -webkit-backdrop-filter: blur(3px);
        backdrop-filter: blur(3px);
        z-index: 5;

        h4 {
            font-size: 1.4em;
            color: var(--cu-brown);
            text-shadow: 0 0 var(--cu-black);
            font-weight: 600;
        }
    }

    &-mapper.on-top {
        z-index: 10;
    }
}

.frame-upload-text {
    color: var(--cu-blue);
    font-size: 1.14rem;
    position: relative;
    overflow: hidden;

    h4 {
        text-decoration: underline;
    }

    span {
        color: var(--cu-gray-100);
        font-style: italic;
        font-weight: 400;
        font-size: 1rem;
    }
}

.frames-upload {
    span {
        color: var(--cu-white);
        align-items: center;
        display: flex;
        padding-top: 15px;
        padding-bottom: 15px;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
    }

    input[type=number] {
        &::-webkit-inner-spin-button {
            opacity: 1;
        }

        background: #11141C;
        // border-radius: 0.35rem;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: var(--cu-white);
        outline: none;
        padding: 0.4rem 1rem;
        width:6rem;
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;

        // height:2rem;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        margin-left: 0.7rem;
    }
}

.frame-top {
    padding-top: 20px;
}

.preview-head {
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    letter-spacing: 0.0666667px;

    /* Colors/grey */

    color: #B3B5BD;

    opacity: 0.6;
}

.input-full {
    background: #272B37;
    border-radius: 8px;
    padding-left: 12px;
}

.upload-image {
    width: fit-content;
}
