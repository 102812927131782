@import '~/src/Styles/abstracts/mixins';
.form-box {
    font-size: 1rem;
    text-transform: capitalize;
}

.selectbox {
    &:focus-visible{
        outline: none;
    }
    &:focus-visible &-placeHolder, &.selectbox-list-open &-placeHolder{
        border: 1px solid var(--cu-white);
    }
    &-placeHolder {
        color: var(--cu-white);
        font-size: 1rem;
        &:focus-visible{
            border: 1px solid var(--cu-white);
        }
    }

    &-description {
        color: var(--cu-white);
        font-size: 1rem;
        margin-left: .5rem;
    }

    &-label {
        position: relative;
        cursor: pointer;

        &::after {
            content: '';
            background-image: url(~/src/Assets/Images/down-arrow.svg);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            width: 1rem;
            height: 1rem;
            position: absolute;
            right: 1rem;
            top: calc(50% + 0.5rem);
            transition: .1s ease;
            transform: rotateZ(0deg);
            transform-origin: center;
        }
    }

    &-list {
        top: 100%;
        left: 0;
        right: 0;
        z-index: 9;
        transition: .1s ease;
        overflow: hidden;
        max-height: 0;
        @include dropdownShadow;

        ul {
            margin-top: 0.21rem;
            border: 1px solid var(--cu-gray-850);
            border-radius: 0.25rem;
            background: var(--cu-gray-850);
            padding: 0;
            overflow: auto;
            text-transform: capitalize;

            li {
                padding: .5rem;
                cursor: pointer;
                text-transform: capitalize;
                &:is(:hover,.active){
                    background: var(--cu-gray-1000);
                }
            }
        }

        &-closed {
            .selectbox-list {
                box-shadow:none;
                animation: fadeOut .1s;
            }

            .selectbox-label {
                &::after {
                    transform: rotateZ(0deg);
                }
            }
        }

        &-open {
            .selectbox-list {
                animation: fadeIn .1s;
            }

            .selectbox-label {
                &::after {
                    transform: rotateZ(-180deg);
                }
            }
        }
    }
}