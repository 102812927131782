.popup {
    &-overlay {
        position: fixed;
        @apply inset-0;
        background: var(--cu-black-trans-500);
        z-index: 10;
        opacity: 0;
        visibility: hidden;
    }

    &-overlay,
    &-wrapper,
    &-template {
        transition: all .5s ease;
    }

    &-wrapper {
        max-height: max-content;
        margin: auto;
        display: flex;
        flex-direction: column;
        padding: 3.42rem;
        position: fixed;
        @apply inset-0;
        border-radius: 2.14rem;
        background: var(--cu-gray-950);
        z-index: 11;
        opacity: 0;
        visibility: hidden;

    }

    &-template {
        max-height: max-content;
        margin: auto;
        display: flex;
        flex-direction: column;
        position: fixed;
        @apply inset-0;
        border-radius: 2.14rem;
        z-index: 11;
        opacity: 0;
        visibility: hidden;

        &-body {
            max-height: calc(100vh - 10rem);
            overflow: auto;
        }

    }

    &.popupActive {

        .popup-overlay,
        .popup-wrapper,
        .popup-template {
            opacity: 1;
            visibility: visible;
        }
    }

    &-title {
        font-size: 1.42rem;
        font-weight: 600;
        color: var(--cu-white);
    }

    &-body {

        padding-top: 3.57rem;
        max-height: calc(100vh - 10rem);
        padding-bottom: 0.5rem;
        @include scroll;
    }

}