.user-details {
    background: var(--cu-black-trans-300);
    border-radius: 0.21rem;
    padding: 1rem 1rem 1rem 2rem;
    color: var(--cu-gray-100);
    font-size: 0.78rem;

    .user {
        min-width: 7.21rem;
        min-height: 7.21rem;
    }

    &-name {
        min-width: 7.64rem;
    }
}

.user-values {
    font-size: 1rem;
    &:not(:last-child) {
        border-bottom: 1px solid var(--cu-gray-910);
    }

    div {
        
        color: var(--cu-gray-100);
    }

    span {
        word-break: break-all;
        flex: 1;
        color: var(--cu-white);
    }
}



.details-user {
    .popup-wrapper {
        background: var(--cu-black-700);
    }
}