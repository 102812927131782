.info {
    background: var(--cu-black-700);
    max-width: 17.42rem;
    border-radius: 0.57rem;

    .loader-image {
        width: 100%;
        min-height: 10.42rem;
    }

    &-title {
        font-size: 1.14rem;
        text-transform: capitalize;
        color: var(--cu-white);
        word-wrap: break-word;
    }

    &-content {
        font-size: 1rem;
        color: var(--cu-gray-100);
    }

    &-desk {
        font-size: 0.85rem;
        color: var(--cu-black-trans-700);
    }

    &-card {
        flex: 1 1 22rem;

        .icon {
            position: absolute;
            top: 0.5rem;
            right: 0.5rem;

            .dropdown-menu-list {
                background: var(--cu-gray-700);

                ul {
                    gap: 0.5rem;
                }

                ul:after {
                    background: var(--cu-gray-700);
                }
            }
        }


    }

    &-menu {
        .icon {
            z-index: 9;
        }
    }
}


.category {
    flex-basis: 21rem;
    min-height: 13.5rem;
    background: var(--cu-black-700);
    border-radius: 0.57rem;

    &-breadcrumbs {
        ul {
            min-width: 13rem;
        }
    }

    &-details {
        &-image {
            max-width: 6.35rem;
            max-height: 6.14rem;
        }
    }

    &-head {
        font-size: 1.28rem;
        color: var(--cu-white);
        text-transform: capitalize;
    }

    &-content {
        font-size: 1rem;
        color: var(--cu-gray-100);
    }

    &-full {
        padding: 1.71rem;
        background: var(--cu-black-700);
        border-radius: 0.57rem;
    }

    &-image {
        flex-basis: 32.14rem;
        max-height: 19.4rem;

        img {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }

    &-add-image {
        img {
            min-width: 100%;
            max-height: 16.57rem;
            border-radius: 0.28rem;
            object-fit: cover;
        }
    }
}

.remove-category {
    .transaction-ok {
        width: auto;
        background: var(--cu-brown);
    }

    .content-card {
        background-color: transparent;
    }

    .popup-title {
        text-transform: capitalize;
    }
}

.view {
    height: calc(100vh - 9rem);

    span {
        font-size: 1.28rem;
        color: var(--cu-white);
    }

    img {
        height: 100%;
        width: 100%;
    }
}

.add-breadcrumbs {
    ul {
        min-width: 24rem;
    }
}


.content {
    &-desc {
        font-size: 1rem;
        color: var(--cu-gray-900);
    }

    &-edit {
        &-image {
            display: flex;
            flex: 1 1 87.5rem;
        }
    }

    &-image {
        img {
            width: 100%;
            height: 100%;
        }

        &-value {
            font-size: 0.85rem;
            font-style: italic;
            color: var(--cu-gray-900);
        }

    }

    &-file {
        position: relative;
    }

    &-video {
        object-fit: cover;
        height: 100%;

        iframe {
            width: 100%;
            height: 100%;
        }
    }
}

.delete-video {
    iframe {
        min-height: 18.4rem;
    }
}

.info-desk .popup-template-body {
    max-height: calc(100vh - 2rem);
    overflow: auto;
}


.info-desk .popup-template {
    background-color: var(--cu-black-700);
}

.info-desk-image {
    img {
        width: 100%;
    }
}

.content-edit-image .add-image-frame {
    padding: 0px;
}


.view-video .image-container {
    height: calc(100vh - 13rem);
}

.background {
    .image {
        height: 11rem;
        overflow: hidden;
        width: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

}
.info-content-upload {
    grid-template-columns: 1fr 25rem;
}